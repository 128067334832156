/* src/styles/HeroSection.css */
.hero {
    text-align: center;
    padding: 80px 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.hero h1 {
    font-size: 96px;
    color: green;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.hero-subtext {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin: 20px auto 0;
    text-align: center;
    box-sizing: border-box;
}

.hero p {
    font-size: 36px;
    color: black;
    margin: 0;
}

/* Responsive styling */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 36px;
    }

    .hero-subtext {
        flex-direction: column;
        width: 90%;
    }

    .hero p {
        font-size: 18px;
    }
}
