/* src/styles/InvitationOnly.css */
.invitation-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    text-align: center;
}

.invitation-only h1 {
    font-family: 'Georgia', serif;
    font-size: 36px;
    color: green;
}
