/* src/components/AuthPage.css */
.auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.auth-container {
  display: flex;
  justify-content: center; /* Center the container */
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  gap: 20px; /* Add gap between forms */
}

.auth-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
}

.auth-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.auth-form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.auth-form button {
  width: 100%;
  padding: 10px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
}

.auth-form label {
  display: block;
  margin: 10px 0 5px;
}

.radio-group {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.radio-group label {
  display: flex;
  align-items: center;
}

.radio-group input {
  margin-right: 5px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .auth-container {
      flex-direction: column;
      align-items: center;
  }

  .auth-form {
      max-width: 100%;
  }
}
