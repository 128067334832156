/* src/styles/Header.css */
.header-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid #ddd;
}

.header {
    width: 100%;
    max-width: 1200px; /* Centering the header content */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
}

.logo {
    font-size: 24px;
    text-decoration: none; /* Remove underline */
    color: black; /* Set the desired color */
}

.nav {
    display: flex;
    align-items: center;
}

.nav a {
    margin: 0 10px;
    text-decoration: none;
    color: black;
}

.nav .sign-in-button {
    background-color: white;
    color: green;
    border: 2px solid green;
    padding: 10px 8px;
    border-radius: 5px;
    text-align: center;
}

.nav .join-button {
    background-color: green;
    color: white;
    padding: 10px 8px;
    border-radius: 5px;
    text-align: center;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

/* Responsive styling */
@media (max-width: 768px) {
    .nav {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 60px;
        right: 20px;
        background: white;
        border: 1px solid #ddd;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        padding: 10px;
        z-index: 1000;
    }

    .nav.open {
        display: flex;
    }

    .nav a {
        margin: 10px 0;
    }

    .nav .sign-in-button, .nav .join-button {
        width: 100%;
        text-align: center;
    }

    .menu-toggle {
        display: block;
    }
}
