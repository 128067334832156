/* src/components/HowItWorks.css */
.how-it-works {
    padding: 80px 20px;
    background-color: white;
    text-align: center;
}

.how-it-works h2 {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: 'Georgia', serif;
    color: green;
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 20px auto;
    box-sizing: border-box;
}

.image-box {
    flex: 1;
    padding: 20px;
}

.image-box img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    max-height: 200px; /* Set a maximum height for the images */
    object-fit: scale-down; /* Ensure images maintain their aspect ratio */
}

.text-box {
    flex: 2;
    text-align: left;
    padding: 20px;
}

.text-box h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: black;
}

.text-box h4 {
    font-size: 20px;
    margin-bottom: 10px;
    color: black;
}

.text-box p {
    font-size: 16px;
    color: #555;
}

.arrow {
    font-size: 30px;
    font-weight: bold;
    color: green;
    margin: 20px 0;
}

/* Responsive styling */
@media (max-width: 768px) {
    .step {
        flex-direction: column;
        text-align: center;
    }

    .text-box {
        text-align: center;
    }

    .image-box img {
        width: 100%;
        max-height: 200px; /* Adjust the height for mobile view */
        object-fit: cover; /* Ensure images maintain their aspect ratio */
    }
}
