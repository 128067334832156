/* src/components/Dietitians.css */
.dietitians {
    padding: 80px 20px;
    background-color: white;
    text-align: center;
}

.dietitians h2 {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: 'Georgia', serif;
    color: green;
}

.accordion {
    margin: 20px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.accordion-title {
    font-size: 18px;
    font-weight: bold;
    color: green;
}

.MuiAccordionSummary-content {
    margin: 0;
}

.MuiAccordion-root:before {
    display: none;
}
