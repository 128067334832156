/* src/styles/SearchBar.css */
.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;  /* Increase padding */
    background-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;  /* Rounded corners */
    flex-wrap: nowrap;  /* Ensure items stay in one line */
    width: 100%;
    max-width: 1200px;  /* Ensure it matches the hero text */
    margin: 20px auto;  /* Center the search bar */
    box-sizing: border-box;
}

.search-bar select, .search-bar button {
    margin: 10px 5px;
    padding: 20px;  /* Increase padding */
    border: 1px solid #ddd;
    border-radius: 5px;
    flex: 1;
    min-width: 200px;
    box-sizing: border-box;
    height: 60px;  /* Set a height */
    background-color: white;
    max-width: 200px; /* Set a maximum width to keep consistency */
}

.search-bar button {
    background-color: green;
    color: white;
    padding: 20px 30px;  /* Increase padding */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.search-bar select {
    max-width: 200px; /* Ensure maximum width is set */
}

/* Responsive styling */
@media (max-width: 768px) {
    .search-bar {
        flex-direction: column;
        width: 90%;  /* Adjust the width for smaller screens */
        padding: 20px;
    }

    .search-bar select, .search-bar button {
        width: 100%;
        margin-top: 10px;
        max-width: 100%; /* Ensure full width in mobile view */
    }
}
